export default {
  namespaced: true,
  // state() {
  //   return {};
  // },
  // getters: {},
  // mutations: {},
  actions: {
    initialize({ dispatch }) {
      console.info('LoginSimple initializing...');
      console.info('LoginSimple initialized.');
    },
  },
};
