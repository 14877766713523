const LoginView = () => import('../views/LoginView.vue');

export default [
  {
    path: '/',
    name: 'landing',
    component: LoginView,
    meta: { guestOnly: true },
  },
];
